<template>
  <span>
    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @init-new-row="handleEvent('init-new-row', $event)"
          @option-changed="handleEvent('option-changed', $event)"
          @row-removed="handleEvent('row-removed', $event)"
        >
          <DxEditing
            :allow-updating="!isDisabled"
            :allow-deleting="!isDisabled"
            :allow-adding="!isDisabled"
            :use-icons="!isDisabled"
            mode="row"
          />

          <DxColumn v-if="!isDisabled" type="buttons">
            <DxButton icon="edit" :onClick="editItem" />

            <DxButton name="delete" />
          </DxColumn>

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn data-field="product_id" caption="Product">
            <DxLookup
              :data-source="productItems"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>

          <DxColumn data-field="serial_no" caption="Serial No." />
          <DxColumn data-field="quantity" caption="Quantity" />

          <DxToolbar>
            <DxItem location="before" name="columnChooserButton" />
            <DxItem location="before" name="searchPanel" />
            <DxItem name="groupPanel" />

            <DxItem v-if="!isDisabled" template="addButtonTemplate" />
          </DxToolbar>

          <template #addButtonTemplate>
            <div>
              <b-button
                variant="primary"
                class="text-capitalize"
                @click="addModal = true"
              >
                Add
              </b-button>
            </div>
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Product"
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <b-row>
        <b-col class="text-right">
          <ProductSearch @onSelect="(v) => selectProduct(v)" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Product Name" label-for="v-name">
            <b-form-input disabled v-model="formDetail.name" id="v-name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Start Date" label-for="v-start_date">
            <flat-pickr
              id="v-start_date"
              v-model="previewStartDate"
              class="form-control"
              :config="flatPickrConfig"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="End Date" label-for="v-end_date">
            <flat-pickr
              id="v-end_date"
              v-model="previewEndDate"
              class="form-control"
              :config="flatPickrConfig"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Quantity" label-for="v-quantity">
            <cleave
              id="v-quantity"
              name="quantity"
              v-model="watchQty"
              class="form-control text-right"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Serial No." label-for="v-serial_no">
            <b-form-input v-model="formDetail.serial_no" id="v-serial_no" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Remarks">
            <b-form-textarea v-model="formDetail.remarks" rows="4" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Instructions">
            <b-form-textarea v-model="formDetail.instruction" rows="4" />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import productsApi from '@api/products';
import { queryParameters } from '@/schema';
// @todo change from utils classes to global-function
import { nowToLocaleDateString } from '@/utils/classes/dates';

import Cleave from 'vue-cleave-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us';

export default {
  name: 'ContractsAddProductsComponent',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Cleave,
  },
  watch: {
    dataSource(v) {
      //
    },
    watchQty(v) {
      const formDetail = this.formDetail;
      if (v) {
        const value = typeof v == 'string' ? v.replace(/,/g, '') : 0;
        if (value) {
          formDetail.quantity = +value;
        }
      }
    },
  },
  data: () => ({
    dataIndex: -1,
    dataItems: [],
    addModal: false,
    flatPickrConfig: {
      enableTime: false,
      dateFormat: 'd/m/Y',
    },
    previewStartDate: new Date(),
    previewEndDate: new Date(),
    formDetail: {
      id: 0,
      header_id: 0,
      product_id: 0,
      serial_no: null,
      quantity: 1,
      startdate: null,
      enddate: null,
      remarks: null,
      instruction: null,
      description: null,
      status: 'O',
    },

    options: {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        rawValueTrimPrefix: true,
        onValueChanged: (e) => {
          if (+e.target.rawValue) {
            //
          }
        },
      },
    },

    productItems: [],
    watchQty: 1,
  }),
  created() {
    this.setDateDisplay();
  },
  mounted() {
    this.loadProducts();
  },
  methods: {
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        product_id: 0,
        uom: null,
        serial_no: null,
        quantity: 1,
        startdate: null,
        enddate: null,
        status: 'O',
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.formDetail = Object.assign({}, defaultForm);

        this.watchQty = 1;
      });
    },
    loadProducts() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      productsApi
        .list(params)
        .then(({ data }) => {
          var items = [];
          data.forEach((e) => {
            const item = {
              id: +e.id,
              name: e.name,
            };

            items.push(item);
          });

          this.productItems = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {
      //
    },
    onReorder(e) {
      console.log(e);
      const visibleRows = e.component.getVisibleRows();
      const toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = this.dataSource.indexOf(e.itemData);
      const newDataSource = [...this.dataSource];

      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);

      this.dataSource = newDataSource;
    },
    editItem(event) {
      if (event) {
        this.addModal = true;
        this.$nextTick(() => {
          const data = event.row.data;
          const product = this.productItems.find((x) => {
            return x.id == data.product_id;
          });

          data.name = product.name;
          this.dataIndex = event.row.dataIndex;
          this.formDetail = Object.assign({}, data);

          this.watchQty = data.quantity;
        });
      }
    },
    previewItem(event) {
      //
    },
    selectProduct(data) {
      if (data) {
        this.loadProducts();

        const dataSource = this.dataSource[this.dataIndex];
        const dataIndex = this.dataIndex > -1;
        const item = {
          id: dataIndex ? dataSource.id : this.dataSource.length + 1,
          product_id: +data.id,
          serial_no: null,
          name: data.name,
          quantity: dataIndex ? String(dataSource.quantity) : '1',
          status: 'O',
        };

        this.watchQty = item.quantity;

        this.formDetail = Object.assign({}, item);
      }
    },
    onSubmit() {
      this.formDetail.startdate = this.previewStartDate;
      this.formDetail.enddate = this.previewEndDate;

      if (this.dataIndex > -1) {
        this.dataSource.splice(this.dataIndex, 1);
        this.dataSource.splice(this.dataIndex, 0, this.formDetail);
      } else {
        this.dataSource.push(this.formDetail);
      }

      this.closeModal();
      this.setDateDisplay();
    },
    setDateDisplay() {
      this.previewStartDate = nowToLocaleDateString;
      this.previewEndDate = nowToLocaleDateString;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
